define('editorium/components/resource-item/component', ['exports', 'ember', 'ember-component', 'editorium/components/resource-item/template'], function (exports, _ember, _emberComponent, _editoriumComponentsResourceItemTemplate) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = _emberComponent['default'].extend({
    layout: _editoriumComponentsResourceItemTemplate['default'],

    item: null,

    uniqueId: computed('item', function () {
      return _ember['default'].guidFor(get(this, 'item'));
    }),

    actions: {
      itemTypeChanged: function itemTypeChanged(newValue) {
        this.set('item.type', newValue);
      },

      submit: function submit() {
        if (this.attrs['on-submit']) {
          this.attrs['on-submit']();
        }
      }
    }
  });
});