define('editorium/components/cards/resources-card/component', ['exports', 'editorium/components/card/component', 'editorium/components/cards/resources-card/template', 'ember-utils', 'ember-runloop'], function (exports, _editoriumComponentsCardComponent, _editoriumComponentsCardsResourcesCardTemplate, _emberUtils, _emberRunloop) {
  exports['default'] = _editoriumComponentsCardComponent['default'].extend({
    layout: _editoriumComponentsCardsResourcesCardTemplate['default'],

    availableTypes: {
      doc: 'Document',
      image: 'Image',
      pdf: 'PDF',
      ppt: 'PowerPoint',
      text: 'Text',
      video: 'Video'
    },

    actions: {
      addItem: function addItem() {
        this.get('props.items').pushObject({
          type: 'pdf',
          label: null,
          url: null,
          thumbnailUrl: null
        });
      },

      deleteItem: function deleteItem(item) {
        var _this = this;

        // Must delete the item in the next run loop due to outside click
        (0, _emberRunloop.next)(function () {
          _this.get('props.items').removeObject(item);
        });
      },

      reorderResourcesItems: function reorderResourcesItems(items) {
        this.get('props.items').setObjects(items);
      }
    },

    didDoneEditing: function didDoneEditing() {
      var _this2 = this;

      this.discardEmptyItems();

      if (this.isEmpty()) {
        (0, _emberRunloop.next)(function () {
          _this2.send('removeCard');
        });
      } else {
        this.commitStore();
      }
    },

    discardEmptyItems: function discardEmptyItems() {
      var filteredItems = this.get('props.items').filter(function (item) {
        return !(0, _emberUtils.isBlank)(item.label) && !(0, _emberUtils.isBlank)(item.url);
      });
      this.set('props.items', filteredItems);
    },

    initDefaultProps: function initDefaultProps() {
      this._super({
        items: []
      });
    },

    initNewCard: function initNewCard() {
      this.send('addItem');
      this.send('edit');
    },

    isEmpty: function isEmpty() {
      return !this.get('props.items').length;
    }
  });
});