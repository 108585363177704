define('editorium/services/config', ['exports', 'ember-service', 'ember-computed'], function (exports, _emberService, _emberComputed) {
  exports['default'] = _emberService['default'].extend({
    // Config aliases
    docTitle: (0, _emberComputed.alias)('config.title'),

    // Default config
    config: {
      title: '(Untitled document)'
    },

    updateConfig: function updateConfig(config) {
      var _this = this;

      Object.keys(config).forEach(function (key) {
        _this.set('config.' + key, config[key]);
      });
    },

    cards: {
      text: {
        name: 'Text',
        icon: 'paragraph',
        config: {}
      },

      image: {
        name: 'Image',
        icon: 'picture-o',
        config: {}
      },

      video: {
        name: 'Video',
        icon: 'youtube-play',
        config: {}
      },

      resources: {
        name: 'Download Resources',
        icon: 'th',
        config: {}
      },

      'media-objects': {
        name: 'Contests',
        icon: 'th-list',
        config: {}
      },

      'horizontal-rule': {
        name: 'Horizontal Rule',
        icon: 'minus',
        config: {}
      },

      'wufoo-form': {
        name: 'Wufoo Form',
        icon: 'fa-pencil-square-o',
        config: {}
      },

      'terracycle-widget': {
        name: 'TerraCycle Widget',
        icon: 'fa-code',
        config: {}
      }
    }
  });
});